.d-none, .hidden {
  display: none !important;
}

.d-block {
  display: block !important;
}

.editable {
  cursor: pointer;
  min-width: 100px;
  text-align: left;
  border: 1px solid silver;
  border-radius: 6px;
  margin: 0 2px;
  padding: 6px;
  font-size: 13px;
  display: inline-block;
}

.info {
  text-align: center;
  color: #789;
  float: none;
  margin-top: 5px;
  font-size: 95%;
}

.info-small {
  color: #789;
  font-size: 75%;
}

.datatable-info {
  text-align: center;
  color: #789;
  float: left;
  margin-top: 5px;
  margin-left: 10px;
}

#msg {
  color: #8b0000;
  padding: 16px;
  font-family: Courier New, Courier, monospace;
  font-weight: 500;
}

.button {
  color: #fff;
  font-variant: small-caps;
  cursor: pointer;
  background: linear-gradient(-27deg, #004d4d, teal);
  border: none;
  border-radius: 10px;
  margin: 0 3px 0 0;
  padding: 4px 6px;
  font-weight: 400;
  display: inline-block;
  font-size: 11px !important;
}

.button-plain {
  color: #004d4d;
  background: none;
}

.button-small {
  padding: 0 6px;
  line-height: 23px;
}

.button-warning {
  background: linear-gradient(-27deg, #cd5c5c, #d2691e);
}

.button-muted {
  background: linear-gradient(-27deg, gray, gray);
}

.button-del {
  color: #dc143c;
  background: none;
}

.button-red {
  background: linear-gradient(-27deg, #cd5c5c, #d2691e);
}

.button-green {
  background: linear-gradient(-27deg, #006400, green);
}

.button-grey {
  background: linear-gradient(-27deg, gray, #a9a9a9);
}

.button.loading {
  display: none;
}

.button.loading:after {
  content: "Wait..";
  display: block;
}

.card {
  width: 75%;
  margin: 10px auto 0;
  overflow: hidden;
}

.card-full {
  width: 100% !important;
}

.card-content {
  background: #fff;
  padding: 10px 20px;
}

.parent-card > .card-content {
  padding: 8px;
}

.parent-card > .card-header {
  border-bottom: none;
}

.card-header {
  text-align: center;
  margin-bottom: 5px;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 400;
}

.card-title {
  font-size: 15px;
}

.badge-small {
  color: #2f4f4f;
  background: wheat;
  border: none;
  border-radius: 3px;
  margin: 0 2px;
  padding: 2px 5px 3px;
  font-size: 11px;
  position: relative;
  top: -1px;
}

.badge-green, .uncertainty-0 {
  color: #fff;
  background: #2e8b57;
}

.badge-red {
  color: #fff;
  background: #cd5c5c;
}

.badge-blue {
  background: #add8e6;
}

.badge-violet {
  color: #fff;
  background: #8a2be2;
}

.badge-orange, .uncertainty-1 {
  color: #fff;
  background: orange;
}

.badge-grey {
  color: #fff;
  background: gray;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loader {
  width: 20px;
  height: 20px;
  background: none;
  border: 3px solid #0000;
  border-left-color: #696969;
  border-radius: 50%;
  animation-name: fadeIn, spin;
  animation-duration: .3s, 1s;
  animation-timing-function: linear, linear;
  animation-iteration-count: 1, infinite;
  display: inline-block;
}

@keyframes spin {
  to {
    transform: scale(1)rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.loader-small {
  width: 12px;
  height: 12px;
  background: none;
  border: 2px solid #0000;
  border-left-color: #696969;
  border-radius: 50%;
  animation-name: spin;
  animation-duration: .8s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  display: inline-block;
}

.modal {
  z-index: 999;
  background-color: #0003;
  transition: all .3s;
  position: fixed;
  inset: 0;
}

.modal > div {
  max-width: 768px;
  max-height: 80%;
  background: #fff;
  border-radius: 4px;
  padding: 2em;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow-y: scroll;
  transform: translate(-50%, -50%);
  box-shadow: 0 3px 14px #0000004d;
}

.modal header {
  font-weight: 500;
}

.modal h1 {
  margin: 0 0 15px;
  font-size: 150%;
}

.modal-close {
  text-align: center;
  width: 30px;
  font-size: 80%;
  line-height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.modal-close:after {
  content: "✕";
}

.tabset-tabs > input[type="radio"] {
  display: none;
  position: absolute;
  left: -200vw;
}

.tabset .tab-panel {
  display: none;
}

.tabset-tabs > input:first-child:checked ~ .tab-panels > .tab-panel:first-child, .tabset-tabs > input:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2), .tabset-tabs > input:nth-child(5):checked ~ .tab-panels > .tab-panel:nth-child(3), .tabset-tabs > input:nth-child(7):checked ~ .tab-panels > .tab-panel:nth-child(4), .tabset-tabs > input:nth-child(9):checked ~ .tab-panels > .tab-panel:nth-child(5), .tabset-tabs > input:nth-child(11):checked ~ .tab-panels > .tab-panel:nth-child(6) {
  display: block;
}

.tabset-tabs > input {
  cursor: pointer;
}

.tabset-tabs > label {
  cursor: pointer;
  padding: 15px 15px 10px;
  font-size: 95%;
  font-weight: 400;
  display: inline-block;
  position: relative;
}

.tabset-tabs {
  text-align: center;
}

.tabset-tabs > input:checked + label:after {
  height: 3px;
  width: 100%;
  content: "";
  background: linear-gradient(to right, #b22222, #cd5c5c) 1px;
  border-radius: 23px;
  padding: 0 2px;
  display: block;
  position: relative;
  top: 3px;
  left: -2px;
}

.tab-panel {
  padding: 30px 0;
}

.tabset-tabs input[type="checkbox"] + label:before, .tabset-tabs input[type="radio"] + label:before {
  display: none;
}

/*# sourceMappingURL=index.43e16a40.css.map */
